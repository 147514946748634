<template>
  <form
      @submit.prevent="submitJob"
      autocomplete="off"
      class="w-full max-h-screen bg-background flex flex-col relative"
      :class="{ 'overflow-y-hidden': isBusy }"
  >
    <div
        v-show="isBusy"
        class="absolute inset-0 bg-faint-white flex items-center justify-center z-10"
    >
      <spinner :color="'text-black'" :size="10"/>
    </div>
    <page-header :title="pageTitle" :info="getJobSheetNo" :enableLogicalBack="true" back-to=" ">

      <AppButton color="indigo" label="Print Job Sheet" @click="printJobSheet" :icon=true class="mr-2">
        <template v-slot:icon>
          <Icon iconType="print" class="mr-2"/>
        </template>
      </AppButton>

      <button
          @click="saveJobSheet"
          type="button"
          class="flex items-center bg-blue-200 border hover:bg-blue-300 border-blue-400 hover:border-blue-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
      >
        <svg
            class="stroke-current h-5 w-5 mr-2"
            fill="none"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            viewBox="0 0 24 24"
        >
          <path
              d="M19 21H5a2 2 0 01-2-2V5a2 2 0 012-2h11l5 5v11a2 2 0 01-2 2z"
          />
          <path d="M17 21v-8H7v8M7 3v5h8"/>
        </svg>
        <span>Save Job Sheet</span>
      </button>
    </page-header>


    <main class="pr-6 pl-6  bg-background flex-1 overflow-y-scroll">

      <div class="flex flex-col bg-white md:flex-row items-center justify-between mt-6">
        <!-- Main Column 1 -->
        <div class=" w-full sm:w-full md:w-2/5 bg-white p-4 rounded md:rounded-none">
          <div class="w-full flex">
            <!-- Sub Column 1 of 1 -->
            <div class="w-2/5 p-1">
              <!-- Row 1 -->
              <div class="">
                <label class="uppercase font-semibold text-sm">Equipment Make</label>

                <Dropdown class="w-full bge-input  rounded" v-model="selectedEquipmentMake"
                          :options="equipmentMakeList"
                          optionLabel="name" placeholder="<Make>"
                />
              </div>
              <!-- Row 2 -->
              <div class="mt-4"> <!-- Added margin-top for alignment -->
                <label class="uppercase font-semibold text-sm">Equipment Model</label>
                <input class="w-full bge-input bge-input-spacing rounded" v-model="job_sheet.product_code" type="text"/>
              </div>
            </div>

            <!-- Sub Column 1 of 2 -->
            <div class="w-3/5 p-1">
              <!-- Row 1 - Split into two parts -->
              <div class="flex">
                <div class="w-9/12 pr-1"> <!-- Adjusted to 80% -->
                  <label class="uppercase font-semibold text-sm">Serial NUMBER</label>
                  <input class="w-full bge-input bge-input-spacing rounded" v-model="job_sheet.serial_number"
                         type="text"/>
                </div>
                <div class="w-3/12 pl-1"> <!-- Adjusted to 20% -->
                  <label class="uppercase font-semibold text-sm">CONTRACT</label>
                  <input class="w-full bge-input bge-input-spacing rounded" type="text"/>
                </div>
              </div>
              <!-- Row 2 -->
              <div class="mt-4"> <!-- Added margin-top for alignment -->
                <label class="uppercase font-semibold text-sm">Description</label>
                <input class="w-full bge-input bge-input-spacing rounded" v-model="job_sheet.name" type="text"/>
              </div>
            </div>
          </div>
        </div>

        <div class=" md:block">
          <div class="w-px bg-orange-500 md:h-32"></div>
        </div>

        <!-- Main Column 2 -->
        <div class=" w-full md:w-2/5 sm:w-full bg-white p-4 rounded  md:rounded-none">
          <div class="flex">
            <!-- Sub Column 2 of 1 -->
            <div class="w-1/2">
              <!-- Row 1 -->
              <div class="mb-2 p-1">
                <label class="uppercase font-semibold text-sm">Engineer</label>
                <StaffAutocomplete class="w-full" v-model="job_sheet.engineer" :forceSelection="true"/>
              </div>
              <!-- Row 2 -->
              <div class="p-1">
                <label class="uppercase font-semibold text-sm">Customer</label>
                <ContactAutocomplete class="w-full" v-model="job_sheet.customer"
                                     @contactSelected="handleContactSelected"
                                     @contactCleared="handleContactCleared" :forceSelection="true" :is_customer="true"/>
              </div>
            </div>
            <!-- Sub Column 2 of 2 -->
            <div class="w-1/2">
              <!-- Row 1 -->

              <div class="p-1 mb-2" v-if="isBusy">
                <div class="animate-pulse">
                  <div class="h-4 bg-gray-300 rounded w-3/4 mb-4"></div>
                  <div class="h-10 bg-gray-300 rounded"></div>
                </div>
              </div>
              <div class="p-1 mb-2" v-else-if="job_sheet.job_id">
                <label class="uppercase font-semibold text-sm">Scheduled Job No</label>
                <input class="w-full bge-input bge-input-spacing rounded" v-model="job_sheet.job_id" type="text"/>
              </div>

              <div class="mb-2" v-else>
                <div class="w-full">
                  <!-- Row 1 split into three parts -->
                  <div class="flex mb-2">
                    <div class="w-1/3 p-1">
                      <label class="uppercase font-semibold text-sm">Internal</label>
                      <Toggle class="w-full bge-input bge-input-spacing rounded"
                              labelPosition="left"
                              label=""
                              :checked="job_sheet.is_internal"
                              labelClasses="uppercase font-semibold text-sm"
                              @value-changed="(value) => {
                            job_sheet.is_internal = value;
                            if (value) job_sheet.is_external = !value;
                          }"
                      />
                    </div>
                    <div class="w-1/3 p-1">
                      <label class="uppercase font-semibold text-sm">External</label>
                      <Toggle class="w-full bge-input bge-input-spacing rounded"
                              labelPosition="left"
                              label=""
                              :checked="job_sheet.is_external"
                              labelClasses="uppercase font-semibold text-sm"
                              @value-changed="(value) => {
                            job_sheet.is_external = value;
                            if (value) job_sheet.is_internal = !value;
                          }"
                      />
                    </div>
                    <div class="w-1/3 p-1">
                      <label class="uppercase font-semibold text-sm">Warranty</label>
                      <Toggle class="w-full bge-input bge-input-spacing rounded"
                              labelPosition="left"
                              label=""
                              :checked="job_sheet.is_warranty"
                              labelClasses="uppercase font-semibold text-sm"
                              @value-changed="(value) => (job_sheet.is_warranty = value)"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <!-- Row 2 -->
              <div class="p-1">
                <label class="uppercase font-semibold text-sm">Agent</label>
                <input class="w-full bge-input bge-input-spacing rounded" v-model="job_sheet.agent_name" type="text"/>
              </div>
            </div>
          </div>
        </div>

        <div class=" md:block">
          <div class="w-px bg-orange-500 md:h-32"></div>
        </div>

        <!-- Main Column 3 -->
        <div class="w-full md:w-1/5 bg-white p-4 rounded md:rounded-none ">
          <div class="flex flex-wrap">
            <!-- Sub Column 1 -->
            <div class="w-full md:w-8/12">
              <!-- Row 1 -->
              <div class="mb-2 p-1">
                <label class="block uppercase font-semibold text-sm">Job Start</label>
                <Calendar appendTo="body"
                          dateFormat="dd/mm/yy"
                          v-model="job_sheet.job_start_date" :showIcon="true"
                />
              </div>
              <!-- Row 2 -->
              <div class="p-1 mt-3">
                <label class="block uppercase font-semibold text-sm">Job End</label>
                <Calendar appendTo="body"
                          dateFormat="dd/mm/yy"
                          v-model="job_sheet.job_end_date" :showIcon="true"
                />
              </div>
            </div>

            <!-- Sub Column 2 (For alignment, mimics the structure of Sub Column 1) -->
            <div class="w-full md:w-4/12">
              <!-- Invisible placeholder for alignment -->
              <!-- Row 1 Placeholder (Maintains layout consistency) -->
              <div class="mb-2 p-1">
                <span class="block invisible uppercase font-semibold text-sm">Placeholder</span>
                <Calendar appendTo="body"
                          dateFormat="dd/mm/yy"
                          v-model="job_sheet.job_start_time" :timeOnly="true"
                />
              </div>
              <!-- Row 2 Placeholder -->
              <div class="p-1 mt-3">
                <span class="block invisible uppercase font-semibold text-sm">Placeholder</span>
                <Calendar appendTo="body"
                          dateFormat="dd/mm/yy"
                          v-model="job_sheet.job_end_time" :timeOnly="true"
                />
              </div>
            </div>
          </div>
        </div>


      </div>

      <!-- Job Description -->
      <div class="w-full bg-white mt-6 p-5 pt-1 rounded shadow-md relative">
        <div class="mt-6">
          <div class="flex items-center mb-2">
            <h3
                class="uppercase text-lg font-semibold text-primary tracking-wide"
            >
              Job Description
            </h3>
            <hr class="flex-1 border-primary ml-4"/>
          </div>
          <Editor editorStyle="height: 120px" v-model="job_sheet.job_description" :options="editorOptions">
            <template #toolbar>
              <span class="ql-formats">
                <button class="ql-bold"></button>
                <button class="ql-italic"></button>
                <button class="ql-underline"></button>
                <button class="ql-list" value="ordered"></button>
                <button class="ql-list" value="bullet"></button>
              </span>
            </template>
          </Editor>
        </div>
      </div>

      <!--  Work Carried out -->
      <div class="w-full bg-white mt-6 p-5 pt-1 rounded shadow-md relative">
        <!-- Header with Vertical Line -->
        <div class="flex items-center mb-5 mt-6">
          <h3 class="uppercase text-lg font-semibold text-primary tracking-wide">
            Work Carried out
          </h3>
          <hr class="flex-1 border-primary mx-4"/>

          <!-- Search Input and Button with Explicit Width -->
          <div class="flex ml-2 w-1/2 lg:w-1/3"> <!-- Adjusted the container width -->
            <input
                v-model="productCodeSearchInput"
                type="text"
                class="flex-grow rounded-l bge-input bge-input-spacing"
                placeholder="Search"

            />
            <button
                @click="triggerProductCodeSearch"
                class="rounded-r bg-blue-200 hover:bg-blue-300 border border-blue-400 hover:border-blue-500 p-2 hover:shadow active:shadow-inner transition-colors duration-300 ease-in-out"
            >
              <Icon iconType="search"/>
            </button>
          </div>
        </div>

        <!-- Two Columns for Controls (Responsive) -->
        <div class="flex flex-col md:flex-row">
          <!-- Left Column Control -->
          <div class="md:w-1/2 md:pr-2 mb-4 md:mb-0">
            <WorkCarried ref="workCarriedOutRef" :initialNotes="workCarriedOut"
                         @items-added="onNotesAdded"></WorkCarried>
          </div>

          <!-- Right Column Control -->
          <div class="md:w-1/2 md:pl-2">
            <DataTable :value="pre_set_text" :selection.sync="selectedPreSetText" dataKey="pre_text_id"
                       class="hide-header">
              <Column selectionMode="multiple" :styless="{width: '3rem'}" :exportable="false"></Column>
              <Column v-for="col in columnsWorkCarriedOut.filter(c => c.field !== 'pre_text_id')"
                      :key="col.field" :field="col.field" :header="col.header"></Column>

            </DataTable>
            <AppButton v-if=" selectedPreSetText && selectedPreSetText.length > 0" color="blue"
                       label="Copy To Work Carried Out"
                       :icon=true
                       class="m-2"
                       @click="copyToWorkCarriedOut">
              <template v-slot:icon>
                <Icon iconType="copy-plus" class="mr-2"/>
              </template>
            </AppButton>
          </div>
        </div>
      </div>

      <!-- Parts / Materials Used -->
      <div class="w-full bg-white mt-6 p-5 pt-1 rounded shadow-md relative">
        <!-- Header with Vertical Line -->
        <div class="flex items-center mb-5 mt-6">
          <h3 class="uppercase text-lg font-semibold text-primary tracking-wide">
            Parts / Materials Used
          </h3>
          <hr class="flex-1 border-primary mx-4"/>

          <!-- Search Input and Button with Explicit Width -->
          <div class="flex ml-2 w-1/2 lg:w-1/3"> <!-- Adjusted the container width -->
            <input
                v-model="productSearchTermInput"
                type="text"
                class="flex-grow rounded-l bge-input bge-input-spacing"
                placeholder="Search"

            />
            <button
                @click="triggerProductSearch"
                class="rounded-r bg-blue-200 hover:bg-blue-300 border border-blue-400 hover:border-blue-500 p-2 hover:shadow active:shadow-inner transition-colors duration-300 ease-in-out"
            >
              <Icon iconType="search"/>
            </button>
          </div>
        </div>

        <!-- Two Columns for Controls (Responsive) -->
        <div class="flex flex-col md:flex-row">
          <!-- Left Column Control -->
          <div class="md:w-1/2 md:pr-2 mb-4 md:mb-0">
            <PartsUsed ref="partsUsedRef" :initialProducts="partsUsed"></PartsUsed>
          </div>

          <!-- Right Column Control -->
          <div class="md:w-1/2 md:pl-2">
            <Products :searchTerm="productSearchTerm" @productSelected="handleProductSelected"></Products>
          </div>
        </div>
      </div>

      <!-- Loan Equipment -->
      <div v-if="job_sheet.is_external" class="w-full bg-white mt-6 p-5 pt-1 rounded shadow-md">
        <div class="mt-6">
          <div class="flex items-center mb-5">
            <h3 class="uppercase text-lg font-semibold text-primary tracking-wide">
              Loan Equipment
            </h3>
            <hr class="flex-1 border-primary ml-4"/>
          </div>

          <!-- Three Columns Layout -->
          <div class="flex flex-col md:flex-row w-full">
            <!-- Column 1 -->
            <div class="w-full md:w-4/12 p-2 mb-4 md:mb-0">
              <label class="uppercase font-semibold text-sm">Loan Equipment Serial Number</label>
              <LoanEquipmentAutoComplete class="w-full" v-model="selectedLoanEquipment"
                                         @equipmentSelected="handleEquipmentSelected"
                                         @equipmentCleared="handleEquipmentCleared" :forceSelection="true"/>
            </div>

            <!-- Column 2 -->
            <div class="w-full md:w-2/12 p-2 mb-4 md:mb-0">
              <label class="uppercase font-semibold text-sm">Action Taken</label>
              <Dropdown class="w-full bge-input  rounded" v-model="selectedLoanEquipmentAction"
                        :options="loanEquipmentActionList"
                        optionLabel="name" placeholder="<Action>"
                        @change="handleLoanEquipmentActionSelected"
                        :disabled="isActionDisabled"
                        :title="equipmentActionName"
              />
            </div>

            <!-- Column 3 -->
            <div class="w-full md:w-6/12 p-2">
              <label class="uppercase font-semibold text-sm">Selected Equipment</label>
              <loanEquipmentList ref="loanEquipmentListRef"
                                 :initialLoanEquipment="assignedLoanEquipment"></loanEquipmentList>
            </div>
          </div>


        </div>
      </div>


      <!-- Manage Files -->
      <div class="w-full bg-white mt-6 p-5 pt-1 rounded shadow-md relative">
        <div class="mt-6">
          <div class="flex items-center mb-5">
            <h3
                class="uppercase text-lg font-semibold text-primary tracking-wide"
            >
              Manage Files
            </h3>
            <hr class="flex-1 border-primary ml-4"/>
          </div>
          <ManageFiles :uploadRefId="job_sheet_id"></ManageFiles>
        </div>
      </div>

      <!-- Quotation Files -->
      <div v-if="job_sheet.quotation_id" class="w-full bg-white mt-6 p-5 pt-1 rounded shadow-md relative">
        <div class="mt-6">
          <div class="flex items-center mb-5">
            <h3
                class="uppercase text-lg font-semibold text-primary tracking-wide"
            >
              Quotation Files
            </h3>
            <hr class="flex-1 border-primary ml-4"/>
          </div>
          <ManageQuotationFiles :canEdit=false :uploadRefId="job_sheet.quotation_id"></ManageQuotationFiles>
        </div>
      </div>


      <!-- Notes -->
      <div class="w-full bg-white mt-6 p-5 pt-1 rounded shadow-md relative">
        <div class="mt-6">
          <div class="flex items-center mb-5">
            <h3
                class="uppercase text-lg font-semibold text-primary tracking-wide"
            >
              Notes
            </h3>
            <hr class="flex-1 border-primary ml-4"/>
          </div>
          <Editor editorStyle="height: 120px" v-model="job_sheet.notes" :options="editorOptions">
            <template #toolbar>
              <span class="ql-formats">
                <button class="ql-bold"></button>
                <button class="ql-italic"></button>
                <button class="ql-underline"></button>
                <button class="ql-list" value="ordered"></button>
                <button class="ql-list" value="bullet"></button>
              </span>
            </template>
          </Editor>
        </div>
      </div>

      <!-- Job sheet completion -->
      <div class="w-full bg-white mt-6 p-5 pt-1 rounded shadow-md">
        <div class="mt-6">
          <div class="flex items-center mb-5">
            <h3 class="uppercase text-lg font-semibold text-primary tracking-wide">
              Job sheet completion
            </h3>
            <hr class="flex-1 border-primary ml-4"/>
          </div>

          <!-- Three Columns Layout -->
          <div class="flex flex-col md:flex-row w-full">
            <!-- Column 1 -->
            <div class="w-full md:w-4/12 p-2 mb-4 md:mb-0">
              <label class="uppercase font-semibold text-sm">Work completed to my satisfaction</label>
              <Dropdown class="w-full bge-input  rounded" v-model="selectedJobSheetCompletionAction"
                        :options="jobSheetCompletionActionList"
                        optionLabel="name" placeholder="<Action>"
              />
            </div>

            <!-- Column 2 -->
            <div class="w-full md:w-3/12 p-2 mb-4 md:mb-0">
              <label class="uppercase font-semibold text-sm">Customer Name</label>
              <input class="w-full bge-input bge-input-spacing rounded" v-model="job_sheet.signing_customer_name"
                     type="text"/>
            </div>

            <!-- Column 3 -->
            <div v-if="job_sheet.is_external" class="w-full md:w-5/12 p-2 ">
              <label class="uppercase font-semibold text-sm mb-1 block">Customer Signature</label>
              <div class="w-full">
                <Signature :uploadRefId="job_sheet_id"
                           :uploadSignatureF="uploadSignature"
                           :getSignatureF="getSignature"
                           class="w-full bge-input bge-input-spacing rounded">
                </Signature>
              </div>
            </div>

            <!-- Column 4 -->
            <div v-else class="w-full md:w-3/12 p-2 mb-4 md:mb-0">
              <label class="uppercase font-semibold text-sm">Order No.</label>
              <input class="w-full bge-input bge-input-spacing rounded" v-model="job_sheet.order_no"
                     type="text"/>
            </div>
          </div>


        </div>
      </div>


      <!--Internal Engineer notes -->
      <div class="w-full bg-white mt-6 p-5 pt-1 rounded shadow-md relative">
        <div class="mt-6">
          <div class="flex items-center mb-5">
            <h3
                class="uppercase text-lg font-semibold text-primary tracking-wide"
            >
              Internal Engineer notes
            </h3>
            <hr class="flex-1 border-primary ml-4"/>
          </div>

          <label class="uppercase font-semibold text-sm">Not on job sheet</label>
          <Editor editorStyle="height: 120px" v-model="job_sheet.internal_engineer_notes" :options="editorOptions">
            <template #toolbar>
              <span class="ql-formats">
                <button class="ql-bold"></button>
                <button class="ql-italic"></button>
                <button class="ql-underline"></button>
                <button class="ql-list" value="ordered"></button>
                <button class="ql-list" value="bullet"></button>
              </span>
            </template>
          </Editor>
        </div>
      </div>

    </main>


  </form>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import Panel from "@/components/Panel.vue";
import Spinner from "@/components/Spinner.vue";
import ContactAutocomplete from '@/components/AutoComplete/ContactAutoComplete.vue';
import LoanEquipmentAutoComplete from '@/components/AutoComplete/LoanEquipmentAutoComplete.vue';
import AppButton from '@/components/Button.vue';
import Icon from '@/components/Icon.vue';
import notificationMixin from "@/mixins/notificationMixin";
import WorkCarried from "@/views/HR/Engineer/JobSheet/Components/WorkCarried.vue";
import PartsUsed from "@/views/HR/Engineer/JobSheet/Components/PartsUsed.vue";
import Products from "@/views/HR/Engineer/JobSheet/Components/Products.vue";
import LoanEquipmentList from "@/views/HR/Engineer/JobSheet/Components/LoanEquipmentList.vue";
import ManageFiles from "@/views/HR/Engineer/JobSheet/Components/ManageFiles.vue";
import ManageQuotationFiles from "@/views/HR/Quotation/Components/ManageFiles.vue";
import Signature from "@/components/Signature.vue";
import StaffAutocomplete from "@/components/AutoComplete/StaffAutoComplete.vue";
import printJS from "print-js";

const Toggle = () => import("@/components/Toggle.vue");
export default {
  name: "ManageJobSheet",
  mixins: [notificationMixin],
  components: {
    StaffAutocomplete,
    PageHeader,
    Panel,
    Spinner,
    ContactAutocomplete,
    LoanEquipmentAutoComplete,
    AppButton,
    Icon,
    Toggle,
    WorkCarried,
    PartsUsed,
    Products,
    LoanEquipmentList,
    ManageFiles,
    Signature,
    ManageQuotationFiles
  },
  props: {
    job_id: String,
    equipment_id: String,
    job_sheet_id: String,
    product_code: String,
    type: String,
  },
  data() {
    return {
      isBusy: false,
      productSearchTermInput: '',
      productSearchTerm: '',
      productCodeSearchInput: '',
      selectedLoanEquipment: null,
      selectedLoanEquipmentAction: null,
      loanEquipmentActionList: [
        {id: 1, name: "Left On Site", short_name: "Left"},
        {id: 2, name: "Collected From Site", short_name: "Collected"}
      ],
      jobSheetCompletionActionList: [
        {id: "yes", name: "YES – JOB COMPLETED",},
        {id: "no", name: "NO – JOB COMPLETED",},
        {id: "incomplete", name: "JOB INCOMPLETE – FURTHER WORK REQUIRED",},
      ],
      selectedJobSheetCompletionAction: null,
      equipmentMakeList: [
        {id: "BOSTON", name: "BOSTON"},
        {id: "BRADBURY", name: "BRADBURY"},
        {id: "CONSUL", name: "CONSUL"},
        {id: "CRYPTON", name: "CRYPTON"},
        {id: "EAE", name: "EAE"},
        {id: "KISMET", name: "KISMET"},
        {id: "LAYCOCK", name: "LAYCOCK"},
        {id: "MAHLE", name: "MAHLE"},
        {id: "NUSBAUM", name: "NUSBAUM"},
        {id: "RAVAGLIOLI", name: "RAVAGLIOLI"},
        {id: "SNAP-ON / SUN", name: "SNAP-ON / SUN"},
        {id: "TECALEMIT", name: "TECALEMIT"},
        {id: "WERTHER", name: "WERTHER"}
      ],
      selectedEquipmentMake: null,
      assignedLoanEquipment: [],
      job_sheet: {
        job_start_date: null,
        job_start_time: null,
        is_internal: true,
        is_external: false,
        is_warranty: false,
      },
      schedule: {},
      customer: {},
      columns: [
        {
          field: "equipment.product_code",
          header: "Code",
          sortable: true,
          custom: false,
          width: '8%',
        },
        {
          field: "equipment.name",
          header: "Description",
          sortable: true,
          custom: false,
          width: '17%',
        },
        {
          field: "equipment.serial_number",
          header: "Serial No.",
          sortable: true,
          custom: false,
          width: '10%',
        },
        {
          field: "equipment.next_calibration",
          header: "Calibration",
          sortable: true,
          custom: true,
          slotName: "timestamp-slot-calibration",
          width: '10%',
        },
        {
          field: "equipment.next_service",
          header: "Service",
          sortable: true,
          custom: true,
          slotName: "timestamp-slot-service",
          width: '10%',
        },
        {
          field: "equipment.warranty_expiry",
          header: "Expiry",
          sortable: true,
          custom: true,
          slotName: "timestamp-slot-warranty",
          width: '10%',
        },
        {
          field: "scheduleDetail.is_install",
          header: "Install",
          custom: true,
          slotName: "job-slot-install",
          width: '7%',
        },
        {
          field: "scheduleDetail.is_repair",
          header: "Repair",
          custom: true,
          slotName: "job-slot-repair",
          width: '7%',
        },
        {
          field: "scheduleDetail.is_service",
          header: "Service",
          custom: true,
          slotName: "job-slot-service",
          width: '7%',
        },
        {
          field: "scheduleDetail.calibration",
          header: "Calibration",
          custom: true,
          slotName: "job-slot-calibration",
          width: '7%',
        },
        {
          field: "scheduleDetail.is_warranty",
          header: "Warranty",
          custom: true,
          slotName: "job-slot-warranty",
          width: '7%',
        },
        {
          field: "scheduleDetail.is_warranty",
          header: "",
          custom: true,
          slotName: "job-slot-sheet",
          width: '3%',
        },


      ],
      editorOptions: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline'],
            [{'list': 'ordered'}, {'list': 'bullet'}],
          ]
        },
        theme: 'snow'
      },
      workCarriedOut: [],
      partsUsed: [],
      selectedPreSetText: null,
      pre_set_text: [],
      columnsWorkCarriedOut: [
        {field: 'pre_text_id', header: 'ID'},
        {field: 'text', header: 'Text'},
      ]
    };
  },
  async mounted() {

    this.isBusy = true;
    if (this.job_sheet_id) {
      const job_sheet = await this.loadJobSheetByJobSheetId();
      this.job_sheet = this.formatJobSheetData(job_sheet);
      this.isBusy = false;
      return;
    }

    if (this.equipment_id) {
      await this.loadEquipment(this.equipment_id);
    }

    if (this.job_id) {
      await this.loadScheduleByJobId(this.job_id);
    }
    if (this.product_code) {
      await this.loadProduct(this.product_code);
    }

    this.setDefaultJobStartTime(this.job_sheet);
    this.setDefaultMake(this.job_sheet);

    this.isBusy = false;
    // const customer_id = this.schedule.customer_id || this.$route.query.customer_id;
    //
    // if (customer_id) {
    //   await this.fetchAndUpdateContactDetails(customer_id);
    //   await this.$store.dispatch("scheduleManage/updateCurrentCustomerId", customer_id);
    // }
    //
    // await this.$store.dispatch("scheduleManage/updateCurrentTable", "equipment");
  },
  // watch: {
  //   'schedule.customer': function (newVal, oldVal) {
  //     if (!newVal || !newVal.contact_id) {
  //       this.customer = {};
  //     }
  //   }
  // },
  computed: {

    pageTitle() {
      return this.job_sheet_id ? "Update Job" : "Create Job";
    },
    isActionDisabled() {
      return this.selectedLoanEquipment === null;
    },
    equipmentActionName() {
      return this.selectedLoanEquipmentAction ? this.selectedLoanEquipmentAction.name : '';
    },
    getJobSheetNo() {
      return this.job_sheet && this.job_sheet.job_sheet_no ? this.job_sheet.job_sheet_no : "";
    }
  },
  methods: {

    async uploadSignature(files, refId) {
      return this.JobSheetService.uploadDocuments(files, refId);
    },
    async getSignature(refId, category) {
      return this.JobSheetService.getJobSheetDocuments(refId, category);
    },
    jobType() {
      if (this.job_id || this.job_sheet.job_id) {
        return 'external';
      } else {
        if (this.job_sheet.is_external) {
          return 'external';
        } else if (this.job_sheet.is_internal) {
          return 'internal';
        } else {
          return 'internal';
        }
      }
    },
    setDefaultJobStartTime(formattedJobSheet) {

      if (!formattedJobSheet.job_start_date) {
        let currentMoment = this.$moment();
        formattedJobSheet.job_start_date = currentMoment.toDate();
        formattedJobSheet.job_start_time = new Date(`1970-01-01T${currentMoment.format('HH:mm:ss')}`);
      }
    },

    setDefaultMake(formattedJobSheet) {

      if (!formattedJobSheet.make) {
        const make = this.equipmentMakeList.find(make => make.id === "BOSTON");
        this.selectedEquipmentMake = make;
      }
    },

    formatJobSheetData(jobSheet) {
      let formattedJobSheet = JSON.parse(JSON.stringify(jobSheet));

      // Formatting job_start_date
      if (formattedJobSheet.job_start_date) {
        let startMoment = this.$moment.unix(formattedJobSheet.job_start_date);
        formattedJobSheet.job_start_date = startMoment.toDate();
        formattedJobSheet.job_start_time = new Date(`1970-01-01T${startMoment.format('HH:mm:ss')}`);
      }

      // Formatting job_end_date
      if (formattedJobSheet.job_end_date) {
        let endMoment = this.$moment.unix(formattedJobSheet.job_end_date);
        formattedJobSheet.job_end_date = endMoment.toDate();
        formattedJobSheet.job_end_time = new Date(`1970-01-01T${endMoment.format('HH:mm:ss')}`);
      }

      if (formattedJobSheet.equipment && formattedJobSheet.equipment.service_agent) {
        formattedJobSheet.agent_name = formattedJobSheet.equipment.service_agent.name;
        formattedJobSheet.product_code = formattedJobSheet.equipment.product_code;
        formattedJobSheet.product_type = formattedJobSheet.equipment.product_type;
        formattedJobSheet.serial_number = formattedJobSheet.equipment.serial_number;
        formattedJobSheet.name = formattedJobSheet.equipment.name;
        formattedJobSheet.equipment_id = formattedJobSheet.equipment.equipment_id;
      } else if (formattedJobSheet.product) {
        formattedJobSheet.product_code = formattedJobSheet.product.product_code;
        formattedJobSheet.product_type = formattedJobSheet.product.product_type;
        formattedJobSheet.name = formattedJobSheet.product.name;
      }

      if (formattedJobSheet.engineer && formattedJobSheet.engineer.first_name && formattedJobSheet.engineer.last_name) {
        formattedJobSheet.engineer.name = `${formattedJobSheet.engineer.first_name} ${formattedJobSheet.engineer.last_name}`
      }
      if (formattedJobSheet.work_carried_out && formattedJobSheet.work_carried_out.length > 0) {
        this.workCarriedOut = formattedJobSheet.work_carried_out;
      }
      //
      if (formattedJobSheet.parts_used && formattedJobSheet.parts_used.length > 0) {
        this.partsUsed = formattedJobSheet.parts_used;
      }

      if (formattedJobSheet.loan_equipment && formattedJobSheet.loan_equipment.length > 0) {
        this.assignedLoanEquipment = formattedJobSheet.loan_equipment;
      }

      if (formattedJobSheet.job_satisfaction) {
        const action = this.jobSheetCompletionActionList.find(action => action.id === formattedJobSheet.job_satisfaction);
        if (action) {
          this.selectedJobSheetCompletionAction = action;
        }
      }

      if (formattedJobSheet.make) {
        const make = this.equipmentMakeList.find(make => make.id === formattedJobSheet.make);
        if (make) {
          this.selectedEquipmentMake = make;
        }
      }

      if (formattedJobSheet.type != null) {
        switch (formattedJobSheet.type) {
          case 'external':
            formattedJobSheet.is_external = true;
            formattedJobSheet.is_internal = false;
            break;
          case 'internal':
            formattedJobSheet.is_internal = true;
            formattedJobSheet.is_external = false;
            break;
          default:
            break;
        }
      }

      return formattedJobSheet;
    },

    triggerProductSearch() {

      this.productSearchTerm = this.productSearchTermInput;
    },

    async triggerProductCodeSearch() {
      try {
        const product = await this.getProduct(this.productCodeSearchInput);

        this.pre_set_text = (product && Array.isArray(product.service_template)) ?
            product.service_template.reduce((acc, template) => {
              if (template.template_id && template.description) {
                acc.push({
                  pre_text_id: template.template_id,
                  text: template.description,
                });
              }
              return acc;
            }, []) : [];
      } catch (error) {
        this.notifyError("Error transforming product templates");
        console.error(error);
      }
    },

    async getProduct(searchTerm = '') {
      try {
        return await this.ProductService.getProductByCode(searchTerm);
      } catch (error) {
        this.notifyError("Error loading products");
        console.error(error);
      }
    },


    handleContactSelected: function (selectedCustomer) {

    },

    handleContactCleared(selectedContact) {

    },

    handleLoanEquipmentActionSelected(item) {
      if (this.selectedLoanEquipment && this.selectedLoanEquipmentAction) {
        const equipmentWithAction = {
          ...this.selectedLoanEquipment,
          action: this.selectedLoanEquipmentAction.short_name
        };
        if (this.$refs.loanEquipmentListRef.addEquipment(equipmentWithAction)) {
          this.selectedLoanEquipment = null;
          this.selectedLoanEquipmentAction = null;
        }
      }
    },
    handleProductSelected(product) {
      this.$refs.partsUsedRef.addProducts([product]);
    },
    handleEquipmentCleared() {
      this.selectedLoanEquipmentAction = null;
    },

    onNotesAdded() {
      this.selectedPreSetText = null;
    },
    copyToWorkCarriedOut() {
      this.$refs.workCarriedOutRef.addItems(this.selectedPreSetText);
    },

    async fetchAndUpdateContactDetails(contact_id) {
      try {
        const response = await this.ContactService.searchContactsWithType({contact_id});
        if (response && response.length > 0) {
          this.updateCustomerDetails(response[0]);
          if (!this.schedule.customer) {
            this.schedule.customer = response[0];
          }
        } else {
          console.error('No contact details found for the given ID');
        }
      } catch (error) {
        console.error('Error fetching contact details:', error);
      }
    },
    formatTimestamp(unixTimestamp) {
      return this.$moment.unix(unixTimestamp).format('DD-MM-YYYY');
    },
    handleEquipmentSelected: function (selectedEquipment) {

    },

    updateCustomerDetails: function (selectedCustomer) {

      this.customer.address = this.formatDefaultAddress(selectedCustomer);
      this.customer.people = selectedCustomer.people;
      this.loadComments(selectedCustomer.contact_id);
    },

    formatDefaultAddress: function (selectedCustomer) {
      if (!selectedCustomer || !selectedCustomer.address) {
        return ``;
      }

      let address = selectedCustomer.address;

      return [
        address.address1,
        address.address2,
        address.address3,
        address.city,
        address.postcode,
      ]
          .filter((part) => !!part)
          .join(", ");
    },

    async loadSchedule(scheduleId) {
      try {
        const scheduleData = await this.EquipmentScheduleService.getScheduleById(scheduleId);
        this.schedule = this.formatScheduleData(scheduleData);
      } catch (error) {
        console.error("Error loading schedule:", error);
      }
    },

    async loadJobSheetByJobSheetId() {
      try {
        const jobSheetData = await this.JobSheetService.getJobSheetById(this.job_sheet_id);


        if (!jobSheetData) {
          return;
        }
        return jobSheetData;
        // this.job_sheet = {
        //   ...jobSheetData,
        // };

      } catch (error) {
        console.error("Error loading schedule:", error);
      }
    },

    async loadScheduleByJobId(jobId) {
      try {
        const scheduleData = await this.EquipmentScheduleService.getScheduleByJobId(jobId);

        if (!scheduleData) {
          return;
        }
        this.job_sheet = {
          ...this.job_sheet,
          customer: scheduleData.customer,
          engineer: scheduleData.engineer,
          job_id: scheduleData.job_id
        };

      } catch (error) {
        console.error("Error loading schedule:", error);
      }
    },
    async loadEquipment(equipmentId) {
      try {

        const equipmentData = await this.EquipmentService.getEquipmentItemById(equipmentId);

        if (!equipmentData) {
          return;
        }


        this.job_sheet = {
          ...this.job_sheet,
          product_code: equipmentData.product_code,
          product_type: equipmentData.product_type,
          serial_number: equipmentData.serial_number,
          name: equipmentData.name,
          agent_name: equipmentData.service_agent ? equipmentData.service_agent.name : "",
          customer: {
            name: equipmentData.owner ? equipmentData.owner.name : "",
            contact_id: equipmentData.owner ? equipmentData.owner.contact_id : ""
          },
          equipment_id: equipmentData.equipment_id
        };

      } catch (error) {
        console.error("Error loading equipment:", error);
      }
    },

    async loadProduct(productCode) {
      try {

        const productData = await this.ProductService.getProductByCode(productCode);

        if (!productData) {
          return;
        }

        this.job_sheet = {
          ...this.job_sheet,
          product_code: productData.product_code,
          product_type: productData.type,
          name: productData.name,
        };

      } catch (error) {
        console.error("Error loading equipment:", error);
      }
    },

    loadComments: function (contact_id) {
      try {
        this.CommentService.getCommentByResourceIdAndTypeId(contact_id, "contact")
            .then(result => {

              console.log("[Comments] (mounted) comments:", result.data);
              const comments = this.getFirstCommentContent(result.data);
              this.$set(this.customer, 'comments', comments);
            })
            .catch(error => {
              console.error("Error fetching comments:", error);
            });
      } catch (error) {
        console.error(error);
      } finally {

      }
    },

    getFirstCommentContent: function (comments) {
      if (comments && comments.length > 0) {
        return comments[0].content;
      }
      return "No Notes";
    },

    formatScheduleData(schedule) {

      let formattedSchedule = JSON.parse(JSON.stringify(schedule));
      let momentObj = this.$moment.unix(formattedSchedule.scheduled_date);
      formattedSchedule.scheduled_date = momentObj.toDate();
      formattedSchedule.scheduled_time = new Date(`1970-01-01T${momentObj.format('HH:mm:ss')}`);

      return formattedSchedule;
    },

    combineAndFormatJobDatesToUnix(job) {

      let startDateTimeString = this.combineDateTimeString(job.job_start_date, job.job_start_time);
      let endDateTimeString = this.combineDateTimeString(job.job_end_date, job.job_end_time);

      let combinedJob = JSON.parse(JSON.stringify(job));
      combinedJob.job_start_date = this.$moment(startDateTimeString, 'DD-MM-YYYY HH:mm').unix();
      combinedJob.job_end_date = this.$moment(endDateTimeString, 'DD-MM-YYYY HH:mm').unix();

      return combinedJob;
    },

    combineDateTimeString(date, time) {

      let dateString = (typeof date === 'string') ? date : this.$moment(date).format('DD-MM-YYYY');
      let timeString = (typeof time === 'string') ? time : this.$moment(time).format('HH:mm');

      return `${dateString} ${timeString}`;
    },

    getAllJobData(job) {
      let formattedJob = this.combineAndFormatJobDatesToUnix(job);

      formattedJob.work_carried_out = this.getWorkCarriedData();
      formattedJob.parts_used = this.getPartsUsedData();

      if (formattedJob.is_external) {
        formattedJob.loan_equipment = this.getLoanEquipmentsData();
      } else {
        formattedJob.loan_equipment = [];
      }

      if (this.selectedJobSheetCompletionAction && this.selectedJobSheetCompletionAction.id) {
        formattedJob.job_satisfaction = this.selectedJobSheetCompletionAction.id;
      }
      if (this.selectedEquipmentMake && this.selectedEquipmentMake.id) {
        formattedJob.make = this.selectedEquipmentMake.id;
      }
      if (formattedJob.customer && formattedJob.customer.contact_id) {
        formattedJob.customer_id = formattedJob.customer.contact_id;
      }
      if (formattedJob.engineer && formattedJob.engineer.staff_id) {
        formattedJob.engineer_id = formattedJob.engineer.staff_id;
      }

      return formattedJob;
    },

    getWorkCarriedData() {
      if (this.$refs.workCarriedOutRef) {

        const allNotes = this.$refs.workCarriedOutRef.getCurrentNotes();
        const nonEmptyNotes = allNotes.filter(note => note.text && note.text.trim().length > 0);
        return nonEmptyNotes;
      }
    },

    getPartsUsedData() {
      return this.$refs.partsUsedRef ? this.$refs.partsUsedRef.getCurrentPartsUsed() : null;
    },

    getLoanEquipmentsData() {
      return this.$refs.loanEquipmentListRef ? this.$refs.loanEquipmentListRef.getCurrentLoanEquipments() : null;
    },

    combineAndFormatToUnix(schedule) {

      let dateString, timeString;

      if (typeof schedule.scheduled_date === 'string') {
        dateString = schedule.scheduled_date;
      } else {
        dateString = this.$moment(schedule.scheduled_date).format('DD-MM-YYYY');
      }

      if (typeof schedule.scheduled_time === 'string') {
        timeString = schedule.scheduled_time;
      } else {
        timeString = this.$moment(schedule.scheduled_time).format('HH:mm');
      }

      const dateTimeString = `${dateString} ${timeString}`;

      const unixTimestamp = this.$moment(dateTimeString, 'DD-MM-YYYY HH:mm').unix();

      let combinedSchedule = JSON.parse(JSON.stringify(schedule));
      combinedSchedule.scheduled_date = unixTimestamp;

      if (schedule.customer && schedule.customer.contact_id) {
        combinedSchedule.customer_id = schedule.customer.contact_id;
      }

      if (schedule.engineer && schedule.engineer.staff_id) {
        combinedSchedule.engineer_id = schedule.engineer.staff_id;
      }

      return combinedSchedule;
    },

    onRowClick(event) {

      this.Edit(event.data);
    },

    saveJobSheet: async function () {
      this.isBusy = true;
      const formattedJobSheet = this.getAllJobData(this.job_sheet);
      formattedJobSheet.type = this.jobType();
      try {

        if (this.job_sheet_id) {
          await this.JobSheetService.updateJobSheet(
              this.job_sheet_id,
              formattedJobSheet,
          );
          this.notifySuccess("Job Sheet updated successfully");
        } else {
          await this.JobSheetService.createJobSheet(formattedJobSheet);
          this.notifySuccess("Job Sheet created successfully");
        }

        if (this.job_sheet_id) {
          const job_sheet = await this.loadJobSheetByJobSheetId();
          this.job_sheet = this.formatJobSheetData(job_sheet);
          return;
        }
        this.$router.go(-1);

      } catch (error) {
        let errorMessage = this.incident_id
            ? "Couldn't update Job Sheet"
            : "Couldn't create Job Sheet";
        this.notifyError(errorMessage);
        console.error(error);
      } finally {
        this.isBusy = false;
      }
    },
    submitJob: async function (event) {
      event.preventDefault();
    },

    replacePlaceholders: function (htmlTemplate, dynamicData) {
      let htmlContent = htmlTemplate;
      Object.keys(dynamicData).forEach(key => {
        const value = dynamicData[key];
        const regex = new RegExp(`{{${key}}}`, "g");
        htmlContent = htmlContent.replace(regex, value);
      });
      return htmlContent;
    },
    dynamicValues: function (jobSheet) {

      const date = jobSheet.job_start_date ? this.$moment(jobSheet.job_start_date).format('DD/MM/YYYY') : 'N/A';
      const timeStart = jobSheet.job_start_date ? this.$moment(jobSheet.job_start_date).format('HH:mm') : 'N/A';
      const timeFinish = jobSheet.job_end_date ? this.$moment(jobSheet.job_end_date).format('HH:mm') : 'N/A';


      const workCarriedOutText = jobSheet.work_carried_out && jobSheet.work_carried_out.map(item => item.text ? item.text : '').join("\n") || '';
      const partsUsedText = jobSheet.parts_used && jobSheet.parts_used.map(part => part.name ? `${part.name} - Qty: ${part.quantity}` : '').join("\n") || '';
      const loanEquipmentText = jobSheet.loan_equipment && jobSheet.loan_equipment.map(equipment => equipment.name ? `${equipment.name}, Serial Number: ${equipment.serial_number}, Action: ${equipment.action}` : '').join("\n") || '';

      const notes = (jobSheet.notes || '').replace(/<[^>]+>/g, '');
      const jobDescription = (jobSheet.job_description || '').replace(/<[^>]+>/g, '');

      const internalChecked = jobSheet.type === 'internal' ? 'checked' : '';
      const externalChecked = jobSheet.type === 'external' ? 'checked' : '';
      const warrantyChecked = jobSheet.is_warranty ? 'checked' : '';

      const checkboxesHtml = `
        <label><input type="checkbox" ${internalChecked}> INTERNAL</label>
        <label><input type="checkbox" ${externalChecked}> EXTERNAL</label>
        <label><input type="checkbox" ${warrantyChecked}> WARRANTY</label>
        `;

      let loanEquipmentHtml = '';
      if (jobSheet.loan_equipment) {
        jobSheet.loan_equipment.forEach(equipment => {
          loanEquipmentHtml += `
         <tr>
               <td>${equipment.serial_number || ''}</td>
               <td>${equipment.name || ''}</td>
               <td>${equipment.action || ''}</td>
         </tr>
        `;
        });
      }

      let workCarriedOutHtml = '';
      if (jobSheet.work_carried_out) {
        jobSheet.work_carried_out.forEach(item => {
          workCarriedOutHtml += `<li>${item.text || ''}</li>\n`;
        });
      }

      let partsUsedHtml = '';
      if (jobSheet.parts_used) {
        jobSheet.parts_used.forEach(part => {
          partsUsedHtml += `
        <tr>
            <td>${part.product_code || ''}</td>
            <td>${part.quantity || ''}</td>
            <td>${part.name || ''}</td>
        </tr>
        `;
        });
      }

      const equipmentMake = jobSheet.make || '';
      const equipmentModel = jobSheet.equipment && jobSheet.equipment.name ? jobSheet.equipment.name : '';
      const equipmentDescription = jobSheet.equipment && jobSheet.equipment.product_type ? jobSheet.equipment.product_type : '';
      const equipmentSerialNumber = jobSheet.equipment && jobSheet.equipment.serial_number ? jobSheet.equipment.serial_number : '';
      let customerPostCode = 'N/A';
      if (jobSheet.customer && jobSheet.customer.addresses) {
        const defaultAddress = jobSheet.customer.addresses.find(address => address.is_default);
        if (defaultAddress) customerPostCode = defaultAddress.postcode || 'N/A';
      }
      const engineerName = jobSheet.engineer ? `${jobSheet.engineer.first_name || ''} ${jobSheet.engineer.last_name || ''}` : '';

      return {
        date: date,
        timeStart: timeStart,
        timeFinish: timeFinish,
        jobSheetNo: jobSheet.job_sheet_no || '',
        workCarriedOut: workCarriedOutText,
        partsUsed: partsUsedText,
        loanEquipment: loanEquipmentText,
        customerName: jobSheet.customer ? jobSheet.customer.name : 'N/A',
        engineerName: engineerName,
        notes: notes,
        signingCustomerName: jobSheet.signing_customer_name || '',
        checkboxesHtml: checkboxesHtml,
        loanEquipmentHtml: loanEquipmentHtml,
        workCarriedOutHtml: workCarriedOutHtml,
        partsUsedHtml: partsUsedHtml,
        equipmentMake: equipmentMake,
        equipmentModel: equipmentModel,
        equipmentDescription: equipmentDescription,
        equipmentSerialNumber: equipmentSerialNumber,
        customerPostcode: customerPostCode,
        customerContract: '',
        descriptionOfWork: jobDescription,
        furtherDetails: '',
        scheduledJobNo: jobSheet.job_id || '-',
        documentsAttached: '',
        customerSignature: '',
      };
    },


    async loadHtmlTemplate() {
      try {
        const response = await fetch("/report_job_sheet.html");
        if (!response.ok) {
          throw new Error("Failed to load report template");
        }
        return await response.text();
      } catch (error) {
        console.error("Error loading HTML template:", error);
        throw error; // Or handle it as needed
      }
    },

    async generateCustomerSignatureHtml() {

      const customerFiles = await this.JobSheetService.getJobSheetDocuments(this.job_sheet.job_sheet_id, 'signature');
      let customerSignatureHtml = '';

      if (customerFiles && customerFiles.length > 0) {
        const customerSignatureUrl = customerFiles[0].access_url;

        customerSignatureHtml = `
      <div class="content-wrapped">
        <img src="${customerSignatureUrl}" alt="Customer Signature" class="signature-image" />
      </div>
    `;
      } else {
        customerSignatureHtml = '<div class="content-wrapped"></div>';
      }

      return customerSignatureHtml;
    },

    async printJobSheet() {
      try {
        if (!this.job_sheet || !this.job_sheet.job_sheet_id) {
          this.notifyWarning("Please save the job sheet before attempting to print.");
        }
        const htmlTemplate = await this.loadHtmlTemplate();
        const dynamicData = this.dynamicValues(this.job_sheet);

        dynamicData.customerSignature = await this.generateCustomerSignatureHtml();


        const printContent = this.replacePlaceholders(htmlTemplate, dynamicData);

        console.log(printContent);

//         const html = `<div>Hello, <b>world!</b></div>`;
//
// // Directly convert the HTML string to a Base64-encoded string
//         const bytes = new TextEncoder().encode(printContent); // Convert the string to Uint8Array
//         const base64 = btoa(String.fromCharCode(...bytes)); // Convert bytes to Base64
//         console.log(base64);
//         const base64Html = 'your_base64_encoded_html_string_here';
//         const apiUrl = 'https://localhost:44377/api/pdfgen';
//         await this.postHtmlGetPdf(apiUrl, base64);

        // Assuming printJS is available and configured
        printJS({
          printable: printContent,
          type: "raw-html",
        });
      } catch (error) {
        console.error("Error printing vehicle record:", error);
      }
    },
    async postHtmlGetPdf(url, base64Html) {
      const headers = new Headers({
        'Content-Type': 'application/json'
      });

      const body = JSON.stringify({
        bodyHtml: base64Html // Ensure your API expects it in this format
      });

      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: headers,
          body: body
        });

        if (response.ok) {
          console.log('Request successful, handling PDF download...');
          const blob = await response.blob();
          this.downloadPdf(blob, "downloaded_pdf.pdf");
        } else {
          console.error('Failed to fetch PDF:', response.status);
          const errorResponse = await response.text();
          throw new Error(errorResponse);
        }
      } catch (error) {
        console.error('Error in fetching PDF:', error);
      }
    },

    downloadPdf(blob, filename) {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    },
  }
}
;
</script>

<style>
.hide-header .p-datatable-thead {
  display: none;
}

</style>

<style scoped>
.p-component-overlay {
  background-color: rgba(0, 0, 0, 0.7) !important;
}

</style>